@import 'Styles/_colors.scss';

.Swap-Detail {
  margin-top: 2rem;

  &__summary {
    display: flex;
    justify-content: center;
    background: $light-grey;
    width: calc(100% + 4rem);
    margin-left: -2rem;
    padding: 0.5rem 0;

    table {
      width: 50%;
      font-weight: 500;
    }
  }

  @media only screen and (max-width: 480px) {
    &__summary {
      width: 100% !important;
      margin-left: 0;
      padding: 1rem;

      table {
        width: 100%;
      }
    }

    &__sequence {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 1rem;
      font-weight: 400;

      &__detail {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 1rem;
        margin: 0.5rem 0;
        border: 1px solid $light-green;
        transition: border 1s ease;

        &:hover {
          border: 1px solid $green;
        }

        // Token wrapper
        > div {
          display: flex;
          align-items: center;
          width: 100%;
        }

        .arrow {
          width: 100%;
          display: flex;
          justify-content: center;

          svg {
            fill: $green;
          }
        }

        .token-logo {
          > * {
            width: 60%;
            height: auto;
          }
        }

        .token {
          display: flex;
          flex-direction: column;

          &__flow {
            font-size: 0.7rem;
            color: $grey;
          }
        }
      }
    }

    .slippage {
      color: $dark-pink;
    }
  }

  @media only screen and (min-width: 481px) {
    &__sequence {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 1.5rem 12rem;
      font-weight: 500;

      &__detail {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 1rem;
        margin: 0.5rem 0;
        border: 1px solid $light-green;
        transition: border 1s ease;

        &:hover {
          border: 1px solid $green;
        }

        // Token wrapper
        > div {
          display: flex;
          align-items: center;
          width: 100%;
        }

        .arrow {
          width: 100%;
          display: flex;
          justify-content: center;

          svg {
            fill: $green;
          }
        }

        .token-logo {
          margin-right: 1rem;
          > * {
            width: 3.5rem;
            height: auto;
          }
        }

        .token {
          display: flex;
          flex-direction: column;

          &__flow {
            font-size: 0.7rem;
            color: $grey;
          }
        }
      }
    }

    .slippage {
      color: $dark-pink;
    }
  }
}

.SwapDetail-message {
  padding: 7rem 0;
  font-weight: 600;
  text-align: center;
}
