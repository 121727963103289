.BlockchainSelector {
  display: flex;
  align-items: center;

  &__title {
    margin-right: 1rem;
  }

  &__blockchain-item {
    width: 30px;
    height: 30px;
    margin-right: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    backdrop-filter: blur(2px);
    transition: background-color 0.3s;

    &.active,
    &:hover {
      img {
        filter: brightness(0) invert(1);
      }

      &.tron {
        background-color: rgb(194, 54, 49);
      }

      &.ontology {
        background-color: #48a3ff;
      }

      &.harmony {
        background-color: #00ade8;
      }

      &.binance {
        background-color: #f3bb2b;
      }

      &.ethereum {
        background-color: #626890;
      }

      &.icon {
        background-color: #1dc5c9;
      }

      &.iotex {
        background-color: #00d4d4;
      }
    }

    &.ethereum {
      img {
        width: auto !important;
        height: 20px !important;
      }
    }

    &.iotex {
      img {
        width: auto !important;
        height: 15px !important;
      }
    }

    img {
      width: 18px;
      height: 18px;
      filter: grayscale(100%);
    }
  }
}
