@import 'Styles/_colors.scss';

.DepositSeed {
  @media only screen and (max-width: 480px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: justify;
    padding: 3rem 1rem;

    &__header {
      padding: 0 !important;
      font-weight: 300;

      > img {
        display: none;
      }
    }

    &__summary {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0 1rem;
      margin: 1rem 0;
      width: 100%;

      &__title {
        color: $grey;
        font-weight: 500;
      }
    }

    &__redeem {
      padding: 1rem 0rem !important;
      flex-direction: column;

      > * {
        margin: 0.5rem 0 !important;
      }
    }
  }

  @media only screen and (min-width: 481px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: justify;

    padding: 0 8rem;

    &__header {
      display: flex;
      align-items: center;
      font-weight: 300;

      > img {
        height: 5rem;
        width: 5rem;
        margin-right: 1rem;
      }
    }
    &__summary {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0 1rem;
      margin: 1rem 0;
      margin-bottom: 3rem;
      width: 100%;

      &__title {
        color: $grey;
        font-weight: 500;
      }
    }
  }

  &__deposited {
    margin-top: 2rem;
    width: calc(100% + 20rem);
    padding: 0.5rem 15rem;
    background-color: $light-grey;
    text-align: center;
  }

  &__redeem {
    display: flex;
    align-items: flex-end;
    width: 100%;
    padding: 3rem 0rem;

    > * {
      margin: 0 0.5rem;
    }

    // TODO ???
    .Button {
      height: 2.5rem;
    }

    .Input {
      margin: 0;
    }
  }

  .Wrapper-Bottom-Button {
    .BottomButton {
      width: 95%;
    }
  }
}
