@import 'Styles/_colors.scss';

@media only screen and (max-width: 480px){
  .UpSuccesful {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    flex: 1;
    margin-top:40px;
  
    h1 {
      color: $green-2 !important;
    }
  
    &__icon {
      > svg {
        height: 10rem;
        width: auto;
        fill: $green;
      }
    }
  
    &__blockchain-explorer {
      margin-bottom: 1.5rem;
      overflow-wrap: anywhere;
      margin: 0 1.5rem;
      a {
        color: $grey !important;
        text-decoration: underline !important;
      }
    }
  
    &__summary {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 0 15rem;
  
      .summary-detail {
        display: flex;
        margin: 2rem 0;
  
        &__logo {
          display: flex;
          justify-content: center;
          height: auto;
          width: 8rem;
  
          > * {
            width: 50%;
            height: auto;
          }
        }
  
        &__reward {
          display: flex;
          flex-direction: column;
          padding: 2.5rem 0;
  
          &__flow {
            font-size: 0.7rem;
            color: $grey;
          }
        }
      }
  
      &__arrow {
        > svg {
          fill: $green;
        }
      }
    }
  }
  
}


@media only screen and (min-width: 481px){
  .UpSuccesful {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    flex: 1;
  
    h1 {
      color: $green-2 !important;
    }
  
    &__icon {
      > svg {
        height: 10rem;
        width: auto;
        fill: $green;
      }
    }
  
    &__blockchain-explorer {
      a {
        color: $grey !important;
        text-decoration: underline !important;
      }
    }
  
    &__summary {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 0 15rem;
  
      .summary-detail {
        display: flex;
        margin: 2rem 0;
  
        &__logo {
          display: flex;
          justify-content: center;
          height: auto;
          width: 8rem;
  
          > * {
            width: 50%;
            height: auto;
          }
        }
  
        &__reward {
          display: flex;
          flex-direction: column;
          padding: 2.5rem 0;
  
          &__flow {
            font-size: 0.7rem;
            color: $grey;
          }
        }
      }
  
      &__arrow {
        > svg {
          fill: $green;
        }
      }
    }
  }
  
}
