$harmony-primary: #00aee9;
$harmony-secondary: #4fe7c8;
$harmony-gradient: linear-gradient(
  to right top,
  $harmony-primary,
  $harmony-secondary
);

$dark-purple: #3b3174;
$light-purple: #626eb0;
$purple-gradient: linear-gradient(180deg, $light-purple, $dark-purple);

$grey: #999;
$dark-grey: #666;
$light-grey: #eee;

$blue: #1da3c9;
$blue-gradient: linear-gradient(to right top, $blue, lighten($blue, 15%));

$green: #3df997;
$green-2: #38f997;
$dark-green: darken($green, 20%);
$light-green: lighten($green, 30%);
$green-filter: invert(100%) sepia(85%) saturate(1599%) hue-rotate(69deg)
  brightness(102%) contrast(92%);

$pink: #fa9191;
$dark-pink: darken(#fa9191, 15%);
$light-pink: lighten(#fa9191, 5%);

$primary-theme: $green;
$secondary-theme: $green;
