@import 'Styles/_colors.scss';

.Farms {
  @media only screen and (max-width: 480px) {
    margin: 0 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: justify;
    padding-bottom: 2rem;
    padding-top: 2rem;

    &__header {
      font-weight: 300;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 !important;

      > img {
        display: none;
      }
    }

    &__content {
      margin-left: 0 !important;

      .farm {
        width: 70vw !important;
      }
    }
  }

  &__header {
    padding: 0 8rem;
    display: flex;
    font-weight: 300;
    text-align: justify;

    > img {
      height: auto;
      width: 10rem;
      margin-right: 2rem;
      align-self: center;
    }
  }

  &__options {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;

    > * {
      display: flex;
      align-items: center;
    }

    &__sort {
      &__title {
        font-weight: 700;
        margin-right: 0.5rem;
      }
    }
  }

  &__content {
    width: calc(100% + 4rem);
    margin: 0.5rem 0;
    margin-left: -2rem;
    padding: 0.5rem 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: #eee;

    .farm {
      position: relative;
      width: calc(100% / 3.5);
      border-radius: 3px;
      margin: 0.5rem;
      background: #fff;
      border: 1px solid $light-green;
      transition: border 0.3s ease;

      &:hover {
        border-color: $green;
      }

      h1 {
        font-size: 1.2rem;
      }

      > div {
        margin: 1rem;
      }

      &__bonus {
        background: $green;
        padding: 0.4rem 1rem;
        border-radius: 4px;
        position: absolute;
        right: -1.5rem;
        font-weight: 700;
        animation: bonus-background 0.5s infinite alternate;
      }

      &__title {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__logo {
          margin-right: 0.5rem;

          svg,
          img {
            height: 2rem;
            width: auto;
          }
        }
      }

      &__apy,
      &__earn {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__label {
          font-weight: 600;
          font-size: 0.9rem;
          color: $dark-grey;
        }
      }

      &__liquidity {
        display: flex;
        flex-direction: column;

        &__label {
          font-weight: 600;
          font-size: 0.9rem;
          color: $dark-grey;
        }

        &__balances {
          display: flex;
          flex-direction: column;
          text-align: right;
        }
      }

      &__earnings {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem !important;

        &__label {
          font-weight: 600;
          font-size: 0.9rem;
          color: $dark-grey;
        }

        &__amount {
          font-weight: 600;
          text-align: right;
        }
      }

      &__claim,
      &__join {
        margin: 0.5rem 1rem !important;

        .Button {
          width: 100%;
          height: 2.25rem;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

@keyframes bonus-background {
  0% {
    background: $green;
  }

  100% {
    background: darken($color: $green, $amount: 5);
  }
}
