@import 'Styles/_colors.scss';

.TransactionsDropdown {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__menu {
    backdrop-filter: blur(2px);
    background: rgba(255, 255, 255, 0.97);
    border-radius: 3px;
    position: absolute;
    top: 35px;
    right: 0;
    width: 200px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

    &.active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        position: relative;
        cursor: pointer;
        text-decoration: none;
        color: #333333;
        padding: 0.6rem 1.5rem;
        display: block;
        border-left: 5px solid $primary-theme;
        border-bottom: 1px solid $primary-theme;

        a {
          color: #333333;
        }

        &.PENDING {
          border-color: $dark-purple;
        }

        &.FAILED {
          border-color: $pink;
        }

        &.header {
          color: $grey;
          text-align: center;
          border: none;
        }

        .status {
          position: absolute;
          bottom: 0.25rem;
          right: 0.5rem;
          font-size: 0.7rem;
          color: $grey;
          font-style: italic;
        }
      }
    }
  }

  &__trigger {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    svg {
      margin-right: 0.25rem;
    }

    &__notification {
      position: absolute;
      top: 5px;
      right: -5px;
      width: 6px;
      height: 6px;
      background: $pink;
      border-radius: 50%;
    }
  }
}
