@import 'Styles/_colors.scss';

@media only screen and (max-width: 480px){
.RedeemPool {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: justify;

  

  &__header {
    display: flex;
    flex-direction:column;
    font-weight: 300;
    margin-top:40px;
    padding: 0 2rem;

    > img {
      width: 10rem;
      height: auto;
      margin-left:20%;
    }
  }

  &__redeem {
    display: flex;
    align-items: flex-end;
    width: 100%;
    padding: 3rem 0rem;

    > * {
      margin: 0 0.5rem;
    }

    // TODO ???
    .Button {
      height: 2.5rem;
    }

    .Input {
      margin: 0;
    }
  }

  &__summary {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 1rem;
    margin: 1rem 0;
    margin-bottom: 3rem;
    width: 100%;

    &__title {
      color: $grey;
      font-weight: 500;
    }
  }
 }
}


@media only screen and (min-width: 481px){
.RedeemPool {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: justify;

  padding: 0 8rem;

  &__header {
    display: flex;
    font-weight: 300;

    > img {
      height: auto;
      width: 10rem;
    }
  }

  &__redeem {
    display: flex;
    align-items: flex-end;
    width: 100%;
    padding: 3rem 0rem;

    > * {
      margin: 0 0.5rem;
    }

    // TODO ???
    .Button {
      height: 2.5rem;
    }

    .Input {
      margin: 0;
    }
  }

  &__summary {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 1rem;
    margin: 1rem 0;
    margin-bottom: 3rem;
    width: 100%;

    &__title {
      color: $grey;
      font-weight: 500;
    }
  }
 }
}