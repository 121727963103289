@import 'Styles/_colors.scss';

.ClaimRewards {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: justify;

  &__header {
    display: flex;
    font-weight: 300;
    padding: 0 8rem;

    > img {
      height: auto;
      width: 7rem;
      margin-right: 2rem;
      align-self: center;
    }
  }

  &__summary {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 1rem -2rem;
    margin-bottom: 3rem;
    width: calc(100% + 4rem);

    .Table {
      background: $light-grey;
      width: 100%;
      padding: 0 8rem;
    }
  }
}

@media only screen and (max-width: 480px) {
  .ClaimRewards {
    &__header {
      flex-direction: column;
      align-items: center;
      padding: 0 2rem;
      margin-top: 2rem;

      > img {
        display: none;
      }
    }

    &__summary {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      overflow: auto;

      .Table {
        width: 100%;
        margin: 1rem 0;
        overflow: auto;
        padding: 0;

        .Table {
          background: $light-grey;
          width: 100% !important;
          padding: 0 1rem !important;
          margin: 0 !important;
        }
      }
    }
  }
}
