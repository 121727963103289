@import 'Styles/_colors.scss';

.Liquidity {
  padding: 0 8rem;
  padding-bottom: 5rem;

  @media only screen and (max-width: 480px) {
    margin: 1rem;
    padding: 0;
    padding-bottom: 2rem;
    padding-top: 2rem;

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;

      > img,
      > svg {
        display: none;
      }
    }
  }

  &__header {
    display: flex;
    font-weight: 300;
    text-align: justify;

    > img {
      height: auto;
      width: 10rem;
      margin-right: 2rem;
    }
  }

  &__selectors {
    margin-top: 2.5rem;
    display: flex;
    justify-content: center;
    text-align: center;

    &__selector {
      margin: 0.5rem;
      border: 1px solid $light-green;
      width: 7rem;
      height: 9rem;
      transition: border 0.3s ease;
      font-size: 0.7rem;
      font-weight: 500;
      padding: 0.2rem;

      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      @media only screen and (max-width: 480px) {
        margin: 0.3rem;
      }

      > div {
        height: 4rem;
        margin: 1rem 0;
      }

      img {
        height: 2.5rem;
        width: auto;
        filter: $green-filter;
      }

      &:hover {
        border-color: $green;
      }
    }
  }
}
