@import 'Styles/_colors.scss';

@media only screen and (max-width: 480px) {
  h1 {
    font-size: larger;
  }

  .Input-Container__wrapper__max .Button {
    padding: 0.3rem 0.6rem;
    font-size: 0.72rem;
    margin-bottom: 4px;
    height: inherit !important;
  }

  .IconPoolJoin {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    flex: 1;
    margin-top: 40px;

    &__band {
      background: $light-grey;
    }

    &__band-summary {
      .table-row {
        font-weight: 500;
      }

      th,
      td {
        text-align: center !important;
      }
    }

    &__band-liquidity {
      padding: 1rem 10rem;

      > div {
        font-weight: 500;
      }

      .Table {
        padding: 0 1rem;
      }
    }

    &__swap-options {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 3rem 0;

      &__pair {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 1.5rem;

        &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 2rem;

          .Button {
            margin-left: 1rem;
            height: 2.5rem;
            width: 8rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .Input {
          margin: 0;
          font-size: 0.68rem;
        }

        &__deposit-icx {
          .Button {
            margin-top: 1rem;
            padding: 0.8rem 0;
          }
        }
      }

      &__add {
        svg {
          height: 2.5rem;
          width: auto;
          fill: $green;
          margin: 1rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 481px) {
  .IconPoolJoin {
    &__band {
      margin-left: -2rem;
      width: calc(100% + 4rem);
      background: $light-grey;
      padding: 0 8rem;
    }

    &__band-summary {
      .table-row {
        font-weight: 500;
      }

      th,
      td {
        text-align: center !important;
      }
    }

    &__band-liquidity {
      padding: 1rem 12rem;

      > div {
        font-weight: 500;
      }

      .Table {
        padding: 0 1rem;
      }
    }

    &__steps {
      position: relative;
      margin: 1.5rem 0;

      &__help {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        padding: 0.5rem 0;
        width: 100%;
        top: -2rem;
        right: -1rem;

        .Button {
          height: 2.5rem;
        }
      }

      .Input-Container__wrapper__max {
        bottom: 1rem !important;
      }

      h1 {
        position: relative;

        span {
          position: absolute;
          right: 1rem;
          top: 0.5rem;
          font-size: 8rem;
          color: rgba(0, 0, 0, 0.1);
        }
      }

      &__step {
        margin: 2.5rem 0;

        &__content {
          display: flex;
          padding-right: 6rem;

          .step-amount {
            width: 65%;

            &__info {
              display: flex;
              justify-content: flex-end;
              margin: 1rem 0;

              .Button {
                margin-left: 1rem;
              }
            }
          }

          .step-actions {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin: 0.5rem 0;
            margin-left: 1rem;
            width: 35%;

            &__help {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              height: 1rem;
              color: $dark-grey;

              svg {
                height: 1rem;
                margin-left: -0.2rem;
              }
            }

            .Button {
              margin-top: 0.5rem;
              width: 100%;
              height: 2.5rem;
            }
          }
        }
      }
    }
  }
}
