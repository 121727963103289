@import 'Styles/_colors.scss';

@media only screen and (max-width: 480px) {
  .RemovePool {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-top: 40px;

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 1rem !important;
      font-weight: 300;

      > img {
        display: none;
      }
    }

    &__table {
      width: 100%;
      margin: 2rem 0;
      overflow: auto;

      .Table {
        background: $light-grey;
        width: 100% !important;
        padding: 0 1rem !important;
        margin: 0 !important;
      }

      .column-right {
        text-align: right;
      }
    }
  }

  .Pool-Button {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.RemovePool {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: justify;

  &__header {
    display: flex;
    padding: 0 8rem;
    font-weight: 300;

    > img {
      width: auto;
      height: 10rem;
      align-self: center;
      margin-right: 2rem;
    }
  }

  &__table {
    width: 100%;
    margin: 2rem 0;

    .Table {
      background: $light-grey;
      margin: 0 -2rem;
      width: calc(100% + 4rem);
      padding: 0 8rem;
    }

    .column-right {
      text-align: right;
    }
  }
}

.Pool-Button {
  width: 100%;
  display: flex;
  justify-content: center;
}
