$breakpoint-phone: 480px;

@media only screen and (max-width: 480px){
  .Bg__skew-1 {
    width: 250px;
    height: 250px;
    position: absolute;
    left: 80px;
    top: 160px;
    margin-top: -340px;
    transform: rotate(30deg);
    transform-origin: center;
    z-index: 4;
    overflow: hidden;
    border-radius: 6px;
    background: linear-gradient(to left, #4beaa9, #78f49f);
  }
  
  .Bg__skew-2 {
    width: 280px;
    height: 250px;
    position: absolute;
    left: 32px;
    top: 0;
    margin-top: -130px;
    transform: rotate(50deg);
    transform-origin: center;
    z-index: 2;
    overflow: hidden;
    background-color: #f1f1f1;
    background-image: url(../../Assets/Background/blocks-black.png);
    background-size: 6px;
    opacity: 0.1;
  }

  .Bg__blocks {
    width: 300px;
    height: 80px;
    position: absolute;
    left: 100px;
    top: 30px;
    z-index: 5;
    opacity: 0.75;
    background-image: url(../../Assets/Background/blocks-white.png);
    background-size: 6px;
  }
}

.Bg {
  width: 1100px;
  z-index: -1;
  margin: auto;
  position: relative;

  @media (min-width: 481px){
  &__blocks {
    width: 300px;
    height: 80px;
    position: absolute;
    right: -70px;
    top: 170px;
    z-index: 5;
    opacity: 0.75;
    background-image: url(../../Assets/Background/blocks-white.png);
    background-size: 6px;
  }
}

  @media (min-width: 481px){
  &__skew-1 {
    width: 900px;
    height: 500px;
    position: absolute;
    right: -40px;
    top: 0;
    margin-top: -330px;
    transform: rotate(30deg);
    transform-origin: center;
    z-index: 4;
    overflow: hidden;
    border-radius: 6px;
    background: linear-gradient(to left, #4beaa9, #78f49f);
    }
  }

  @media (min-width: 481px){
  &__skew-2 {
    width: 1000px;
    height: 400px;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: -130px;
    transform: rotate(50deg);
    transform-origin: center;
    z-index: 2;
    overflow: hidden;
    background-color: #f1f1f1;
    background-image: url(../../Assets/Background/blocks-black.png);
    background-size: 6px;
    opacity: 0.1;
  }
}
}
