@import 'Styles/_colors.scss';

.CheckboxSelector {
  display: flex;
  align-items: center;

  &__label {
    font-weight: 700;
    margin-left: 0.5rem;
  }

  &__checkbox {
    height: 1.5rem;
    width: 3rem;
    border-radius: 1rem;
    cursor: pointer;
    position: relative;
    background: $light-grey;
    transition: background 1s;

    &.active {
      background: $dark-green;

      .CheckboxSelector__checkbox__ball {
        left: calc(100% - 1.1rem - 3px);
      }
    }

    &:hover {
      .CheckboxSelector__checkbox__ball {
        box-shadow: 0 0 0 2px $green;
      }
    }

    &__ball {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 3px;
      border-radius: 50%;
      height: 1.1rem;
      width: 1.1rem;
      background: $dark-grey;
      transition: left 0.3s;
    }
  }
}
