@import 'Styles/_colors.scss';

.Loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000000;

  .MuiLinearProgress-colorPrimary {
    background-color: $pink;
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: $light-pink;
  }
}
