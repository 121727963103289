@import 'Styles/_colors.scss';

.DocumentationDropdown {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__menu {
    backdrop-filter: blur(2px);
    background: rgba(255, 255, 255, 0.97);
    border-radius: 3px;
    position: absolute;
    top: 35px;
    right: 0;
    width: 200px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

    &.active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        cursor: pointer;
        text-decoration: none;
        color: #333333;
        padding: 0.6rem 1.5rem;
        display: block;

        a {
          color: #333333;
        }

        &.header {
          color: $grey;
        }

        &.bordered {
          border-bottom: 1px solid #dddddd;
        }

        &.logout {
          color: $pink;
        }
      }
    }
  }

  &__trigger {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      margin-right: 0.25rem;
    }
  }
}
