.notification-hash {
  > a {
    display: inline-block;
    width: 15rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: underline;
    color: #000;
    font-size: 0.75rem;
  }
}
