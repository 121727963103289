@import 'Styles/_colors.scss';

@media only screen and (max-width: 480px) {
  h1 {
    display: inline-block;
    font-size: 1.5rem;
  }
}

.Pool {
  @media only screen and (max-width: 480px) {
    margin: 0 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: justify;
    padding-bottom: 2rem;
    padding-top: 2rem;

    &__header {
      font-weight: 300;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 !important;

      > img {
        display: none;
      }
    }
  }

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: justify;

  &__header {
    display: flex;
    padding: 0 8rem;
    font-weight: 300;

    > img {
      height: auto;
      width: 10rem;
      margin-right: 2rem;
      align-self: center;
    }
  }

  &__table {
    width: 100%;
    margin: 2rem 0;

    .Table {
      background: $light-grey;
      margin: 0 -2rem;
      width: calc(100% + 4rem);
      padding: 0 4rem;

      tr {
        th:first-child,
        td:first-child {
          width: 34px;
          height: 3rem;
          padding: 0.5rem 0;
          display: flex;
          position: relative;

          svg,
          img {
            width: 25px;
            height: auto;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            z-index: 3;
            background: #fff;
            border-radius: 50%;
          }

          svg:not(:first-child),
          img:not(:first-child) {
            position: absolute;
            left: 14px;
            z-index: 2;
          }
        }
      }
    }

    @media only screen and (max-width: 480px) {
      width: calc(100% + 4rem);
      overflow: auto !important;

      .Button {
        font-size: 8.5px;
        padding: 0.4rem 1rem;
      }

      .Table {
        background: #eee;
        padding: 0 12px;
        margin: 0;
        font-size: 10px;

        tr {
          th:first-child,
          td:first-child {
            display: none;
          }

          th {
            font-size: 10px;
          }
        }
      }
    }

    .column-right {
      text-align: right;
    }
  }
}

.Pool-Button {
  width: 100%;
  display: flex;
  justify-content: center;
}
