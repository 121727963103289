@import 'Styles/_colors.scss';

.TopHeader {
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 1rem;
  z-index: 100;
  font-size: 0.9rem;

  &.hide {
    display: none;
  }

  > span {
    display: flex;
    align-items: center;

    > * {
      margin: 0 0.75rem;
    }
  }

  .blockchain {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.015);
    padding: 0.5rem;
    backdrop-filter: blur(2px);
    border-radius: 3px;
    backdrop-filter: blur(2px);
    transition: background 0.3s linear;
    margin: 0 0.5rem;
    line-height: 0;

    &:hover {
      background: #fff;
    }

    img,
    svg {
      width: 24px;
      height: auto;
    }
  }

  .menu-item {
    display: flex;
    align-items: center;
    cursor: pointer;

    a {
      display: flex;
      align-items: center;
      color: #000;
    }

    svg {
      margin-right: 0.25rem;
    }
  }
}
