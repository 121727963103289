.EthereumPerformSwapButton {
  width: 60%;
  display: flex;
  justify-content: space-between;

  > * {
    width: 48%;
  }

  @media only screen and (max-width: 480px) {
    width: 90%;
  }
}
