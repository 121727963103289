@import 'Styles/_colors.scss';

@mixin token-size {
  display: flex;
  align-items: center;

  > img {
    height: auto;
    width: 22px;
    margin-right: 1rem;
  }

  > svg {
    height: auto;
    width: 22px;
    margin-right: 1rem;
  }
}

@mixin font-style {
  color: #000;
  font-weight: 500;
}

.TokenSelector {
  width: 12rem;

  &__control {
    background-color: $green !important;

    &:hover {
      border-color: $dark-green !important;
    }

    &--is-focused,
    &--menu-is-open {
      box-shadow: 0 0 1px $dark-green !important;
    }
  }

  .TokenSelector__menu-list {
    background: $green;
  }

  .TokenSelector__option {
    @include token-size();

    background: $green;
    transition: background 0.3s ease;

    &:hover {
      background: $light-green;
    }

    &.TokenSelector__option--is-selected {
      @include font-style();
      background: $light-green;
    }
  }

  .TokenSelector__single-value {
    @include token-size();
    @include font-style();
  }

  .TokenSelector__indicator-separator {
    border-color: #000 !important;
    background-color: rgba(0, 0, 0, 0.7) !important;
  }

  .TokenSelector__indicator svg {
    fill: rgba(0, 0, 0, 0.7) !important;
  }
}
