.BottomButton {
  width: 65%;

  .Button {
    background: #000;
    color: #fff;
    padding: 1rem 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
