@import 'Styles/_colors.scss';

.Button {
  color: #fff;
  padding: 0.5rem 1.5rem;
  border: 0;
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 0.025rem;
  font-size: 0.75rem;
  background: #000;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.8s linear, background 0.3s linear;
  outline: 0;
  display: inline-flex;
  align-items: center;

  &:disabled {
    background: $dark-grey !important;
  }

  &:hover {
    opacity: 0.8;
  }

  &.white {
    background: none;
    color: #000;
  }

  &.blue {
    background: $blue;
    color: #fff;
  }

  &.green {
    background: $green;
    color: #000;
  }

  &.dashed {
    background: none !important;
    color: #000 !important;
    border: 1px dashed #000;
  }

  &__icon {
    margin-right: 0.5rem;

    img,
    svg {
      height: 22px;
      width: 22px;
    }
  }
}
